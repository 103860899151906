import React from 'react';
import ComponentCreator from '@docusaurus/ComponentCreator';

export default [
  {
    path: '/api-explorer',
    component: ComponentCreator('/api-explorer', 'e8e'),
    exact: true
  },
  {
    path: '/auth',
    component: ComponentCreator('/auth', '85d'),
    exact: true
  },
  {
    path: '/dashboard',
    component: ComponentCreator('/dashboard', 'd63'),
    exact: true
  },
  {
    path: '/endpoint',
    component: ComponentCreator('/endpoint', 'dce'),
    exact: true
  },
  {
    path: '/search',
    component: ComponentCreator('/search', '822'),
    exact: true
  },
  {
    path: '/docs',
    component: ComponentCreator('/docs', '109'),
    routes: [
      {
        path: '/docs',
        component: ComponentCreator('/docs', 'd15'),
        routes: [
          {
            path: '/docs/tags',
            component: ComponentCreator('/docs/tags', 'fde'),
            exact: true
          },
          {
            path: '/docs/tags/active',
            component: ComponentCreator('/docs/tags/active', '3a2'),
            exact: true
          },
          {
            path: '/docs/tags/alive',
            component: ComponentCreator('/docs/tags/alive', '45e'),
            exact: true
          },
          {
            path: '/docs/tags/anatomy',
            component: ComponentCreator('/docs/tags/anatomy', '8f7'),
            exact: true
          },
          {
            path: '/docs/tags/api',
            component: ComponentCreator('/docs/tags/api', 'a44'),
            exact: true
          },
          {
            path: '/docs/tags/application',
            component: ComponentCreator('/docs/tags/application', 'be8'),
            exact: true
          },
          {
            path: '/docs/tags/authentication',
            component: ComponentCreator('/docs/tags/authentication', 'c10'),
            exact: true
          },
          {
            path: '/docs/tags/authorisation',
            component: ComponentCreator('/docs/tags/authorisation', '644'),
            exact: true
          },
          {
            path: '/docs/tags/authorise',
            component: ComponentCreator('/docs/tags/authorise', '80b'),
            exact: true
          },
          {
            path: '/docs/tags/calls',
            component: ComponentCreator('/docs/tags/calls', '140'),
            exact: true
          },
          {
            path: '/docs/tags/code',
            component: ComponentCreator('/docs/tags/code', '749'),
            exact: true
          },
          {
            path: '/docs/tags/commission',
            component: ComponentCreator('/docs/tags/commission', '122'),
            exact: true
          },
          {
            path: '/docs/tags/concept',
            component: ComponentCreator('/docs/tags/concept', 'fff'),
            exact: true
          },
          {
            path: '/docs/tags/contracts',
            component: ComponentCreator('/docs/tags/contracts', '8f2'),
            exact: true
          },
          {
            path: '/docs/tags/copy-trading',
            component: ComponentCreator('/docs/tags/copy-trading', '804'),
            exact: true
          },
          {
            path: '/docs/tags/country-list',
            component: ComponentCreator('/docs/tags/country-list', 'fb6'),
            exact: true
          },
          {
            path: '/docs/tags/create',
            component: ComponentCreator('/docs/tags/create', 'e21'),
            exact: true
          },
          {
            path: '/docs/tags/earn',
            component: ComponentCreator('/docs/tags/earn', '39c'),
            exact: true
          },
          {
            path: '/docs/tags/earning',
            component: ComponentCreator('/docs/tags/earning', '316'),
            exact: true
          },
          {
            path: '/docs/tags/example',
            component: ComponentCreator('/docs/tags/example', 'bb1'),
            exact: true
          },
          {
            path: '/docs/tags/getting-started',
            component: ComponentCreator('/docs/tags/getting-started', '1db'),
            exact: true
          },
          {
            path: '/docs/tags/guide',
            component: ComponentCreator('/docs/tags/guide', '3fb'),
            exact: true
          },
          {
            path: '/docs/tags/history',
            component: ComponentCreator('/docs/tags/history', 'bf9'),
            exact: true
          },
          {
            path: '/docs/tags/integration',
            component: ComponentCreator('/docs/tags/integration', '56d'),
            exact: true
          },
          {
            path: '/docs/tags/intro',
            component: ComponentCreator('/docs/tags/intro', '208'),
            exact: true
          },
          {
            path: '/docs/tags/javascript',
            component: ComponentCreator('/docs/tags/javascript', '8d7'),
            exact: true
          },
          {
            path: '/docs/tags/keep',
            component: ComponentCreator('/docs/tags/keep', '8e7'),
            exact: true
          },
          {
            path: '/docs/tags/markup',
            component: ComponentCreator('/docs/tags/markup', '6ee'),
            exact: true
          },
          {
            path: '/docs/tags/monetization',
            component: ComponentCreator('/docs/tags/monetization', '22c'),
            exact: true
          },
          {
            path: '/docs/tags/monetize',
            component: ComponentCreator('/docs/tags/monetize', '7bc'),
            exact: true
          },
          {
            path: '/docs/tags/proposal',
            component: ComponentCreator('/docs/tags/proposal', '6e6'),
            exact: true
          },
          {
            path: '/docs/tags/quick-start',
            component: ComponentCreator('/docs/tags/quick-start', 'fb3'),
            exact: true
          },
          {
            path: '/docs/tags/quick-startup',
            component: ComponentCreator('/docs/tags/quick-startup', '5f5'),
            exact: true
          },
          {
            path: '/docs/tags/register',
            component: ComponentCreator('/docs/tags/register', 'ec8'),
            exact: true
          },
          {
            path: '/docs/tags/setup',
            component: ComponentCreator('/docs/tags/setup', '489'),
            exact: true
          },
          {
            path: '/docs/tags/status',
            component: ComponentCreator('/docs/tags/status', '3b0'),
            exact: true
          },
          {
            path: '/docs/tags/symbol',
            component: ComponentCreator('/docs/tags/symbol', 'ce2'),
            exact: true
          },
          {
            path: '/docs/tags/symbols',
            component: ComponentCreator('/docs/tags/symbols', 'f6a'),
            exact: true
          },
          {
            path: '/docs/tags/ticks',
            component: ComponentCreator('/docs/tags/ticks', '525'),
            exact: true
          },
          {
            path: '/docs/tags/trading',
            component: ComponentCreator('/docs/tags/trading', 'ce7'),
            exact: true
          },
          {
            path: '/docs/tags/tutorial',
            component: ComponentCreator('/docs/tags/tutorial', 'f7a'),
            exact: true
          },
          {
            path: '/docs/tags/website',
            component: ComponentCreator('/docs/tags/website', 'd83'),
            exact: true
          },
          {
            path: '/docs/tags/websocket',
            component: ComponentCreator('/docs/tags/websocket', '26a'),
            exact: true
          },
          {
            path: '/docs',
            component: ComponentCreator('/docs', '2b7'),
            routes: [
              {
                path: '/docs/category/code-examples',
                component: ComponentCreator('/docs/category/code-examples', '714'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/category/core-concepts',
                component: ComponentCreator('/docs/category/core-concepts', '112'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/category/guides',
                component: ComponentCreator('/docs/category/guides', 'd82'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/category/javascript',
                component: ComponentCreator('/docs/category/javascript', 'a36'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/category/javascript-examples',
                component: ComponentCreator('/docs/category/javascript-examples', '112'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/category/languages',
                component: ComponentCreator('/docs/category/languages', 'e30'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/code-examples/javascript/active-symbols/',
                component: ComponentCreator('/docs/code-examples/javascript/active-symbols/', '51a'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/code-examples/javascript/contracts-for-symbol/',
                component: ComponentCreator('/docs/code-examples/javascript/contracts-for-symbol/', 'b91'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/code-examples/javascript/keep-alive/',
                component: ComponentCreator('/docs/code-examples/javascript/keep-alive/', '680'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/code-examples/javascript/proposal/',
                component: ComponentCreator('/docs/code-examples/javascript/proposal/', '8c0'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/code-examples/javascript/ticks-history/',
                component: ComponentCreator('/docs/code-examples/javascript/ticks-history/', '9bb'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/code-examples/javascript/ticks/',
                component: ComponentCreator('/docs/code-examples/javascript/ticks/', '3f8'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/code-examples/javascript/website-status/',
                component: ComponentCreator('/docs/code-examples/javascript/website-status/', '3a8'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core-concepts/api-calls-anatomy/',
                component: ComponentCreator('/docs/core-concepts/api-calls-anatomy/', '150'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core-concepts/authorization-authentication/',
                component: ComponentCreator('/docs/core-concepts/authorization-authentication/', '372'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core-concepts/copytrading/',
                component: ComponentCreator('/docs/core-concepts/copytrading/', '941'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/core-concepts/websocket/',
                component: ComponentCreator('/docs/core-concepts/websocket/', 'bea'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/guides/about-the-deriv-api/',
                component: ComponentCreator('/docs/guides/about-the-deriv-api/', '2b2'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/guides/monetizing-the-deriv-api/',
                component: ComponentCreator('/docs/guides/monetizing-the-deriv-api/', 'c31'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/guides/oauth2/',
                component: ComponentCreator('/docs/guides/oauth2/', 'e1e'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/intro',
                component: ComponentCreator('/docs/intro', '0c5'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/languages/intro',
                component: ComponentCreator('/docs/languages/intro', '627'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/languages/javascript/get-country-list/',
                component: ComponentCreator('/docs/languages/javascript/get-country-list/', 'e87'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/languages/javascript/project-setup/',
                component: ComponentCreator('/docs/languages/javascript/project-setup/', '4e6'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/languages/javascript/websocket-connection/',
                component: ComponentCreator('/docs/languages/javascript/websocket-connection/', '27d'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/setting-up-a-deriv-application',
                component: ComponentCreator('/docs/setting-up-a-deriv-application', 'be1'),
                exact: true,
                sidebar: "tutorialSidebar"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: ComponentCreator('/', 'e5f'),
    exact: true
  },
  {
    path: '*',
    component: ComponentCreator('*'),
  },
];
